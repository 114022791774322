import { Box, Typography } from "@mui/material"
import InfiniteReel from './InfiniteTextReel';

// NOTE: Title to provide shortcut to adjust individual image size and positioning when rendered with imageTextBlock.
const imageTextList = [
    {
        title: 'Floramize',
        image: require('../assets/imgs/feature_1.png'),
        imageScale: 100,
        mobileMaxWidth: '550px',
        header: <span>floramize<br />your plant</span>,
        text: 'Turn your ordinary plant into an exciting new Florami by scanning it with our app!'
            + ' A Florami is a virtual version of your existing plant and allows you to interact with your plant'
    },
    {
        title: 'Water',
        image: require('../assets/imgs/feature_2.png'),
        imageScale: 65,
        mobileMaxWidth: '330px',
        header: <span>care for<br />your<br />florami</span>,
        text: 'With our database of thousands of plants,'
            + ' find the exact care requirements for your plant to ensure your Florami stays healthy'
    },
    {
        title: 'Happy',
        image: require('../assets/imgs/feature_3.png'),
        imageScale: 85,
        mobileMaxWidth: '440px',
        header: <span>keep your<br />florami<br />happy</span>,
        text: 'Simply caring for your plant in real life isn’t enough.'
            + ' Floramis have feelings just like any other pet and require attention to keep them happy'
    }
]

const order = {
    imageFirst: 'image-first',
    textFirst: 'text-first'
}

const imageTextBlock = (imageText, thisOrder, index, isMobile) => {
    if (!isMobile) {
        var left;
        var right;
        const textAlign = thisOrder === order.imageFirst ? 'left' : 'right';
        if (thisOrder === order.imageFirst) {
            left = <Box sx={{ width: '50%', margin: 'auto' }}>
                <img style={{ width: imageText.imageScale + '%' }} src={imageText.image} alt='Feature' />
            </Box>;
            right = <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 5, gap: 5 }}>
                <Typography variant="h2" align={textAlign}>{imageText.header}</Typography>
                <Typography variant="h4" align={textAlign}>{imageText.text}</Typography>
            </Box>;
        } else if (thisOrder === order.textFirst) {
            left = <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 5, gap: 5 }}>
                <Typography variant="h2" align={textAlign}>{imageText.header}</Typography>
                <Typography variant="h4" align={textAlign}>{imageText.text}</Typography>
            </Box>;
            right = <Box sx={{ width: '50%', margin: 'auto', ml: imageText.title === 'Water' ? 15 : 0 }}>
                <img style={{ width: imageText.imageScale + '%' }} src={imageText.image} alt='Feature' />
            </Box>;
        }

        return (
            <Box key={index} sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                {left}
                {right}
            </Box>
        )
    } else {
        const gap = imageText.title === 'Floramize' ? 0 : imageText.title === 'Water' ? 4 : 2;
        const shift = imageText.title === 'Happy' ? '3vw' : 0;
        return (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', mt: 5, md: 5, gap: gap }}>
                <Typography sx={{ mb: 1 }} variant="h2" align='center'>{imageText.header}</Typography>
                <img style={{ width: imageText.imageScale * 0.75 + '%', maxWidth: imageText.mobileMaxWidth, position: 'relative', right: shift }} src={imageText.image} alt="Feature" />
                <Typography sx={{ padding: 2 }} variant="h4" align='center'>{imageText.text}</Typography>
            </Box>
        )
    }
}


const Features = () => {
    return (
        <div
            id="features"
            style={{
                marginTop: '50px',
                display: "flex",
                flexDirection: "column",
                width: "100%"
            }}>
            <InfiniteReel
                reelTexts={["features"]}
                duration={[8]}
                directionArr={[1]}
                affectOnScrollArr={true}
            />
            <div className='page-content'>
                <Box sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'column', p: '5vw', pt: 0 }}>
                    {imageTextList.map((imgText, index) => imageTextBlock(imgText, index % 2 === 0 ? order.imageFirst : order.textFirst, index, false))}
                </Box>
                <Box sx={{ display: { xs: 'flex', md: 'none' }, flexDirection: 'column', p: '7.5vw', pt: 0 }}>
                    {imageTextList.map((imgText, index) => imageTextBlock(imgText, index % 2 === 0 ? order.imageFirst : order.textFirst, index, true))}
                </Box>
            </div>
        </div>
    )
}

export default Features;