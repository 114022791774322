import { Typography, Box } from "@mui/material"
import { useState } from "react"
import InfiniteReel from "./InfiniteTextReel";

const cards = [
    {
        icon: require("../assets/imgs/home_icon.png"),
        icon_scale: '50%',
        title: "Build a home",
        description: "Build a home for your Florami by taking care of it and buying cool new furniture",
        backMedia: {
            src: require("../assets/imgs/home_screen.png"),
            safariSrc: require("../assets/imgs/safari/home_screen.png"),
            type: 'img'
        }
    },
    {
        icon: require("../assets/imgs/social_icon.png"),
        icon_scale: '68%',
        title: "Social Features",
        description: "Connect with your friends and express yourself with a plethora of customizable cosmetics",
        backMedia: {
            src: require("../assets/imgs/social_screen.png"),
            safariSrc: require("../assets/imgs/safari/social_screen.png"),
            type: 'img'
        }
    },
    {
        icon: require("../assets/imgs/game_icon.png"),
        icon_scale: '68%',
        title: "Minigames",
        description: "Play exciting minigames with your Florami and your friends!",
        backMedia: {
            src: require("../assets/imgs/gameplay.mp4"),
            safariSrc: require("../assets/imgs/safari/gameplay.mp4"),
            type: 'vid'
        }
    }
]

const CustomCard = (props) => {
    const [flipped, setFlipped] = useState(false);
    const handleClick = () => setFlipped(!flipped);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: { xs: '75vw', sm: "45vw", md: "18vw" } }} onClick={handleClick}>
            <div style={{ width: '100%', backgroundColor: flipped ? '#20C658' : '#268513' }} className={`card ${flipped ? 'flipped' : ''}`}>
                <div className='front' style={{ height: '75%' }}>
                    <Box sx={{ display: 'flex', height: '50%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <img style={{ width: props.card.icon_scale, marginTop: props.card.title === 'Minigames' ? '7%' : 0 }} src={props.card.icon} alt='card image' />
                    </Box>
                    <Box sx={{ display: 'flex', height: '50%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography fontSize={{ xs: '18px', sm: '20px' }} color='primary.contrastText' fontFamily='Poppins' variant="h5" align='center'>{props.card.title}</Typography>
                        <Typography fontSize={{ xs: '14px', sm: '16px' }} color='primary.contrastText' variant="body1" align='center'>{props.card.description}</Typography>
                    </Box>
                </div>
                <div className='back' style={{ height: '100%' }}>
                    {
                        props.card.backMedia.type === 'img'
                            ? <img style={{ height: '100%' }} alt='Card Image' src={props.card.backMedia.src} />
                            : <video style={{ height: '100%' }} src={props.card.backMedia.src} autoPlay={true} loop={true} muted={true} />
                    }
                </div>
                <div
                    className={`safari-back`}
                    style={{ display: !props.isSafari ? 'none' : flipped ? 'flex' : 'none', height: '100%' }}
                >
                    {
                        props.card.backMedia.type === 'img'
                            ? <img style={{ height: '100%' }} src={props.card.backMedia.safariSrc} />
                            : <video style={{ height: '100%' }} src={props.card.backMedia.safariSrc} autoPlay={true} loop={true} muted={true} />
                    }
                </div>
            </div>
        </Box>
    )
}

const CardsPage = () => {
    const uA = navigator.userAgent;
    const isSafari = /Safari/i.test(uA) && !/Mobi|Android/i.test(uA);

    return (
        <div>
            <InfiniteReel
                reelTexts={["coming soon"]}
                duration={[12]}
                directionArr={[1]}
                affectOnScrollArr={true}
            />
            <div className="page-content">
                <Box sx={{ display: 'flex', flexDirection: 'column', p: '5vw', mb: "50px" }}>
                    {/* <Typography sx={{ ml: '5vw', mb: 5, wordSpacing: '0.2em' }} color='secondary.main' lineHeight='0.9' fontFamily='Poppins' variant="h1" align="left">Coming soon</Typography> */}
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'space-evenly' }}>
                        {cards.map((card, index) => <CustomCard card={card} key={index} isMobile={false} isSafari={isSafari} />)}
                    </Box>
                    <Box sx={{ display: { xs: 'flex', sm: 'none' }, flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
                        {cards.map((card, index) => <CustomCard card={card} key={index} isMobile={true} isSafari={isSafari} />)}
                    </Box>
                    <Box sx={{ display: { xs: 'none', sm: 'flex', md: 'none' }, flexDirection: 'column', alignItems: 'center', gap: '50px' }}>
                        <CustomCard card={cards[0]} isMobile={true} />
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                            <CustomCard card={cards[1]} isMobile={true} isSafari={isSafari} />
                            <CustomCard card={cards[2]} isMobile={true} isSafari={isSafari} />
                        </Box>
                    </Box>
                    <Typography sx={{ mt: 3, pl: '10%', pr: '10%' }} color='primary.main' align='center' variant='subtitle1'>We have a lot more features in the pipeline and will be releasing regular updates to keep things fresh.</Typography>
                    {/* <Typography sx={{mr: 5, mt: 5}} color='secondary.main' fontFamily='Poppins' variant="h1" fontSize={96} align="right">SOON</Typography> */}
                </Box>
            </div>
        </div>
    )
}

export default CardsPage;