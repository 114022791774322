import ModelLoading from "../common/ModelLoading";


function LinkRedirect(props) {
    
    window.location = props.link;

    return (
        <div className='page'>
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh"
            }}>
                <ModelLoading />
            </div>
        </div>
    );
}

export default LinkRedirect;