import {
    RepeatWrapping,
    MeshBasicMaterial
} from 'three';

// Author: Felix Mariotto

// Based on Lee Stemkoski's work who coded the core texture offsetting part :
// http://stemkoski.github.io/Three.js/Texture-Animation.html

function AnimMixer() {

    var animMaterials = [];

    var api = {
        animMaterials: animMaterials,
        update: update,
        Anim: Anim,
        Action: Action,
    };

    // Update every stored actionSprite if needed.
    function update(delta) {
        animMaterials.forEach((animMaterial) => {
            if (animMaterial.paused === false) {
                updateAction(animMaterial.currentAction, delta * 1000);
            };
        });
    };

    // This offsets the texture to make the next frame of the animation appear.
    function offsetTexture(animMaterial) {
        // console.log(animMaterial);
        animMaterial.map.offset.x = animMaterial.getColumn() / animMaterial.tilesHoriz;
        animMaterial.map.offset.y = (animMaterial.tilesVert - animMaterial.getRow() - 1) / animMaterial.tilesVert;
    };

    // This is called during the loop, it first check if the animation must
    // be updated, then increment actionSprite.currentTile, then call offsetTexture().
    // Various operations are made depending on clampWhenFinished and hideWhenFinished
    // options.
    function updateAction(action, milliSec) {
        action.animMaterial.currentDisplayTime += milliSec;

        while (action.animMaterial.currentDisplayTime > action.tileDisplayDuration) {

            var shouldStop = false;

            action.animMaterial.currentDisplayTime -= action.tileDisplayDuration;
            if (!action.reversed) {
                action.animMaterial.currentTile = (action.animMaterial.currentTile + 1);
                if (action.animMaterial.currentTile > action.indexEnd) {
                    if (action.pauseLast) {
                        action.animMaterial.currentTile = action.indexEnd;
                    } else {
                        action.animMaterial.currentTile = action.indexStart;
                    }
                    shouldStop = true;
                }

            } else {
                action.animMaterial.currentTile = (action.animMaterial.currentTile - 1);
                if (action.animMaterial.currentTile <= 0) {
                    if (action.pauseLast) {
                        action.animMaterial.currentTile = action.indexStart;
                    } else {
                        action.animMaterial.currentTile = action.indexEnd;
                    }
                    shouldStop = true;
                }
            }
            
            // Restarts the animation if the last frame was reached at last call.
            if (shouldStop) {
                var stopAnim = true;

                // Call the user callbacks on the event 'loop'
                if (action.mustLoop === true) {
                    stopAnim = false;
                    if (action.timesToLoop !== -1) {
                        action.timesLooped += 1;
                        if (action.timesLooped === action.timesToLoop) {    
                            stopAnim = true;
                            action.timesToLoop = -1;
                            action.timesLooped = 0;
                        }
                    }
                }
                
                if (stopAnim) {
                    if (action.clampWhenFinished === true) {
                        action.animMaterial.paused = true;
                        if (action.hideWhenFinished === true) {
                            action.animMaterial.visible = false;
                        };

                    } else { // must restart the animation before to stop
                        action.animMaterial.paused = true;
                        if (action.hideWhenFinished === true) {
                            action.animMaterial.visible = false;
                        };

                        // Call updateAction() a last time after a frame duration,
                        // even if the action is actually paused before, in order to restart
                        // the animation.
                        setTimeout(() => {
                            updateAction(action, action.tileDisplayDuration);
                        }, action.tileDisplayDuration);

                    };
                };
            };

            offsetTexture(action.animMaterial);
        };
    };

    // reveal the sprite and play the action only once
    function playOnce(reversed, pauseLast) {
        this.reversed = reversed;
        this.pauseLast = pauseLast;
        this.mustLoop = false;
        this.animMaterial.currentAction = this;
        if (!reversed) {
            this.animMaterial.currentTile = this.indexStart;
        } else {
            this.animMaterial.currentTile = this.indexEnd;
        }
        offsetTexture(this.animMaterial);
        this.animMaterial.paused = false;
        this.animMaterial.visible = true;
    };

    // resume the action if it was paused
    function resume() {
        // this is in case setFrame was used to set a frame outside of the
        // animation range, which would lead to bugs.
        if (this.currentTile > this.indexStart &&
            this.currentTile < this.indexEnd) {
            this.currentTile = this.indexStart;
        };
        this.animMaterial.paused = false;
        this.animMaterial.visible = true;
    };

    // reveal the sprite and play it in a loop
    function playLoop() {
        this.mustLoop = true;
        this.animMaterial.currentAction = this;
        this.animMaterial.currentTile = this.indexStart;
        offsetTexture(this.animMaterial);
        this.animMaterial.paused = false;
        this.animMaterial.visible = true;
    };

    function playTimes(times) {
        this.mustLoop = true;
        this.animMaterial.currentAction = this;
        this.animMaterial.currentTile = this.indexStart;
        offsetTexture(this.animMaterial);
        this.animMaterial.paused = false;
        this.animMaterial.visible = true;
        this.timesToLoop = times;
        this.timesLooped = 1;
    }

    // pause the action when it reach the last frame
    function pauseNextEnd() {
        this.mustLoop = false;
    };

    // pause the action on the current frame
    function pause() {
        this.animMaterial.paused = true;
    };

    // pause and reset the action
    function stop() {
        this.animMaterial.currentDisplayTime = 0;
        this.animMaterial.currentTile = this.indexStart;
        this.animMaterial.paused = true;
        if (this.hideWhenFinished === true) {
            this.animMaterial.visible = false;
        };
        offsetTexture(this.animMaterial);
    };

    // Set manually a frame of the animation. Frame indexing starts at 0.
    function setFrame(frameID) {
        this.paused = true;
        this.currentTile = frameID;
        offsetTexture(this);
    };

    // returns the row of the current tile.
    function getRow() {
        return Math.floor(this.currentTile / this.tilesHoriz);
    };

    // returns the column of the current tile.
    function getColumn() {
        return this.currentTile % this.tilesHoriz;
    };

    function Anim(texture, alphaMap, tilesHoriz, tilesVert) {
        texture.wrapS = texture.wrapT = RepeatWrapping;
        texture.repeat.set(1 / tilesHoriz, 1 / tilesVert);
        
        let animMaterial = new MeshBasicMaterial({
            map: texture, transparent: true
        });

        animMaterial.isIndexedSprite = true;

        animMaterial.tilesHoriz = tilesHoriz;
        animMaterial.tilesVert = tilesVert;
        animMaterial.tiles = (tilesHoriz * tilesVert);
        animMaterial.currentDisplayTime = 0;
        animMaterial.currentTile = 0;
        animMaterial.paused = true;
        animMaterial.currentAction = null;

        animMaterial.setFrame = setFrame;
        animMaterial.getRow = getRow;
        animMaterial.getColumn = getColumn;

        offsetTexture(animMaterial);
        animMaterials.push(animMaterial);
        return animMaterial;
    }

    /*
        SpriteMixer.Action returns an object containing the informations related to a
        specific sequence in an actionSprite. For instance, if the actionSprite
        contains 20 tiles, an Action could start at tile 5 and finish at tile 8.
        Action( actionSprite:ActionSprite, indexStart:integer, indexEnd:integer, tileDisplayDuration:integer )
            - actionSprite is a SpriteMixer.ActionSprite, containing a loaded texture with tiles
            - indexStart is the starting tile of the animation, index starts at 0.
            - indexEnd is the ending tile of the animation
            - tileDisplayDuration is the duration of ONE FRAME in the animation
    */
    function Action(animMaterial, indexStart, indexEnd, tileDisplayDuration) {
        if (!animMaterial.isIndexedSprite) {
            throw 'Error : "texture" argument must be an indexedTexture.';
        };

        return {
            type: "animMaterial",
            playOnce,
            playLoop,
            playTimes,
            resume,
            pause,
            pauseNextEnd,
            stop,
            clampWhenFinished: true,
            hideWhenFinished: false,
            mustLoop: true,
            timesToLoop: -1,
            timesLooped: 0,
            indexStart,
            indexEnd,
            tileDisplayDuration,
            animMaterial
        };

    };

    return api;
};

export { AnimMixer };