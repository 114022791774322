import BlumContent from "../components/BlumContent";
import BlumBackground from "../components/BlumBackground";

function Blum() {
    return (
        <div className='page'>
            <BlumBackground />
            <BlumContent />
        </div>
    )
}

export default Blum;