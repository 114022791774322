import { CustomButton } from './Trial'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import { NavLink } from 'react-router-dom';

const ConnectBar = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    const buttonWidth = isSmallScreen ? "50px" : isMediumScreen ? "60px" : "70px";  // Adjust the width based on screen size
    const buttonGap = isSmallScreen ? 4 : isMediumScreen ? 6 : 10;  // Adjust the gap based on screen size

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                width: "100%",
                bgcolor: '#FAFAF1',
                zIndex: 1               // make sure it's on top of everything else
            }}
        >

            <Box
                sx={{
                    display: 'flex',
                    width: "100%",
                    height: { xs: '60px', sm: '80px' },
                    alignContent: 'center',
                    justifyContent: 'center',
                    gap: buttonGap,
                    zIndex: 1,
                    pb: 10
                }}>
                {/* <CustomButton width='43px' href={"https://www.linkedin.com/company/floramis/"} buttonImage={require("../assets/imgs/linkedin.png")} /> */}
                <CustomButton width={buttonWidth} href={"https://www.instagram.com/floramisapp"} buttonImage={require("../assets/imgs/iglogo.png")} />
                <CustomButton width={buttonWidth} href={"https://www.facebook.com/profile.php?id=100083270180353"} buttonImage={require("../assets/imgs/fblogo.png")} />
                <CustomButton width={buttonWidth} href={"https://www.tiktok.com/@floramisapp"} buttonImage={require("../assets/imgs/tiktoklogo.png")} />
                <CustomButton width={buttonWidth} href={"https://discord.gg/VmR734HB84"} buttonImage={require("../assets/imgs/dcordlogo.png")} />
            </Box>

            {/* <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignContent: "center",
                    pb: 2
                }}
            >
                <ButtonBase
                    sx={{
                        px: 2
                    }}
                    href={"/privacy-policy"}
                >
                    <Typography color='primary.contrastText'>Privacy Policy</Typography>
                </ButtonBase>

                <ButtonBase
                    sx={{
                        px: 2
                    }}
                    href={"/pdpa"}
                >
                    <Typography color='primary.contrastText'>PDPA</Typography>
                </ButtonBase>
            </Box> */}
        </Box>
    )
}

export default ConnectBar;